import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  (window.location.hostname === 'localhost'
    ? 'http://localhost:3501/api'
    : '/api');


export const AuthContext = createContext();

// Provider component
export const AuthProvider = ({ children }) => {
  const existingToken = localStorage.getItem('token');
  const [authToken, setAuthToken] = useState(existingToken);
  const [user, setUser] = useState(null);

  // Function to handle login
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        username,
        password,
      });

      const token = response.data.token;
      localStorage.setItem('token', token);
      setAuthToken(token);

      const decoded = jwtDecode(token);
      setUser({ userId: decoded.userId });
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  };

  // Function to handle logout
  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setUser(null);
  };

  // Check token expiry on initial load and when authToken changes
  useEffect(() => {
    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        if (decodedToken.exp * 1000 < Date.now()) {
          logout();
        } else {
          setUser({ userId: decodedToken.userId });
        }
      } catch (error) {
        // Invalid token
        logout();
      }
    }
  }, [authToken]);

  const isAuthenticated = !!authToken;

  return (
    <AuthContext.Provider value={{ authToken, user, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
import React, { useState } from 'react';
import axios from 'axios';

const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL ||
    (window.location.hostname === 'localhost'
    ? 'http://localhost:3001/api'
    : '/api');

const Register = ({ onRegistrationSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!username || !password || !email) {
        setErrorMessage('All fields are required.');
        setIsLoading(false);
        return;
    }

    try {
        const response = await axios.post(`${API_BASE_URL}/register`, {
        username,
        password,
        email,
        });

        setSuccessMessage(response.data.message || 'Registration successful.');
        // Clear form fields
        setUsername('');
        setPassword('');
        setEmail('');
        // After a delay, redirect back to login screen
        setTimeout(() => {
        onRegistrationSuccess();
        }, 30000);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
        } else {
        setErrorMessage('Server error during registration.');
        }
    } finally {
        setIsLoading(false);
    }
    };

    return (
    <div className="container mt-4">
        <h2>Register</h2>
        {errorMessage && (
        <div className="alert alert-danger mt-3">{errorMessage}</div>
        )}
        {successMessage && (
        <div className="alert alert-success mt-3">{successMessage}</div>
        )}

        <form onSubmit={handleRegister} className="mt-4">
        <div className="mb-3">
            <label className="form-label">Username</label>
            <input
            type="text"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            />
        </div>
        <div className="mb-3">
            <label className="form-label">Email</label>
            <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
        </div>
        <div className="mb-3">
            <label className="form-label">Password</label>
            <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            />
            <div className="form-text">
            Password must be at least 6 characters.
            </div>
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Registering...' : 'Register'}
        </button>
        </form>

        <div className="mt-4">
        Already have an account?{' '}
            <button className="btn btn-secondary ms-2" onClick={onRegistrationSuccess}>
                Login here
            </button>
        </div>
    </div>
    );
};

export default Register;
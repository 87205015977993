import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import Login from './Login';
import Register from './Register'; 
import ReactMarkdown from 'react-markdown';

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  (window.location.hostname === 'localhost'
    ? 'http://localhost:3001/api'
    : '/api');

const App = () => {
  const { isAuthenticated, logout, authToken } = useContext(AuthContext);

  const [conversations, setConversations] = useState([]);
  const [showRegister, setShowRegister] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newConversationName, setNewConversationName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newSystemPrompt, setNewSystemPrompt] = useState('');
  const [newModel, setNewModel] = useState('');
  const [newProvider, setNewProvider] = useState('');

  const [isConversationPaneOpen, setIsConversationPaneOpen] = useState(true);

  const [userInput, setUserInput] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // State to store models
  const [models, setModels] = useState([]);

  // State variables
  const [blobName, setBlobName] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  // Memoize the API instance
  const api = useMemo(() => {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }, [authToken]);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await api.get('/conversations');
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      setErrorMessage(error.response?.data?.error || 'Failed to fetch conversations.');
    }
  }, [api]);

  // Fetch all conversations on load
  useEffect(() => {
    if (isAuthenticated) fetchConversations();
  }, [isAuthenticated, fetchConversations]);

  // Memoize the handleImageUpload function
  const handleImageUpload = useCallback(
    async (file) => {
      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);

      try {
        setIsLoading(true);

        const response = await api.post('/upload-to-azure', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { blobName: uploadedBlobName } = response.data;

        setBlobName(uploadedBlobName);

        // Fetch the image preview URL using the 'image-preview' endpoint
        const previewResponse = await api.get(`/image-preview/${uploadedBlobName}`);
        const { imageUrl: previewUrl } = previewResponse.data;

        setImagePreviewUrl(previewUrl);

        setErrorMessage(''); // Clear any previous error messages
      } catch (error) {
        setErrorMessage(error.response?.data?.error || 'Failed to upload image. Please try again.');
        console.error('Image upload error:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    const handlePaste = (event) => {
      const items = event.clipboardData.items;
      for (let item of items) {
        if (item.type.startsWith('image')) {
          const blob = item.getAsFile();
          // Create a File instance
          const file = new File([blob], 'pasted-image.png', {
            type: blob.type,
          });
          handleImageUpload(file);
          event.preventDefault(); // Prevent default paste behavior
          break; // Stop after handling the first image
        }
      }
    };

    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [handleImageUpload]);

  const selectConversation = async (id) => {
    try {
      const response = await api.get(`/conversations/${id}`);
      setSelectedConversation(response.data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to load conversation.');
      console.error('Error loading conversation:', error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!userInput.trim() && !imageUrl && !blobName) return;

    setIsLoading(true);
    try {
      let content = {};

      if (userInput.trim()) {
        content.text = userInput.trim();
      }

      if (blobName) {
        content.image_blob = blobName;
      } else if (imageUrl) {
        content.image_url = imageUrl.trim();
      }

      const response = await api.post(
        `/conversations/${selectedConversation._id}/send`,
        { content }
      );

      setSelectedConversation(response.data);
      setUserInput('');
      setImageUrl('');
      setBlobName(''); // Clear the blobName after sending
      setImagePreviewUrl(''); // Clear the image preview
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to send message.');
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleConversationPane = () => {
    setIsConversationPaneOpen(!isConversationPaneOpen);
  };

  const openModal = async () => {
    try {
      // get the list of models
      const response = await api.get('/models');
      setModels(response.data);
      setIsModalOpen(true);
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to fetch models.');
      console.error('Error fetching models:', error);
    }
  };
  

  
  // Render the models in the select box, grouped by the provider
  const renderModelOptions = () => {
    const groupedModels = models.reduce((acc, model) => {
      if (!acc[model.provider]) {
        acc[model.provider] = [];
      }
      acc[model.provider].push(model);
      return acc;
    }, {});
  
    return Object.keys(groupedModels).map((provider) => (
      <optgroup key={provider} label={provider}>
        {groupedModels[provider].map((model) => (
          <option key={model.model} value={model.model} data-provider={provider}>
            {model.model}
          </option>
        ))}
      </optgroup>
    ));
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setNewConversationName('');
    
    setNewSystemPrompt(''); // Clear the system prompt
  };

  const handleCreateConversation = async () => {
    if (!newConversationName.trim()) {
      setErrorMessage('Conversation name cannot be empty.');
      return;
    }

    try {
      const response = await api.post('/conversations', {
        name: newConversationName,
        systemPrompt: newSystemPrompt, // Include the system prompt
        model: newModel,
        provider: newProvider
      });
      setConversations([...conversations, response.data]);
      closeModal();
      setErrorMessage(''); // Clear any previous error messages
    } catch (error) {
      setErrorMessage(error.response?.data?.error || 'Failed to create conversation.');
      console.error('Error creating conversation:', error);
    }
  };

  return (
    <div className="container mt-4">
      {!isAuthenticated ? (
        showRegister ? (
          // Show the Register component
          <Register
            onRegistrationSuccess={() => {
              setShowRegister(false);
            }}
          />
        ) : (
          // Show the Login component
          <div>
            <Login />
            <div className="mt-3">
              <p>
                Don't have an account?{' '}
                <button
                  className="btn btn-secondary ms-2"
                  onClick={() => setShowRegister(true)}
                >
                  Register here
                </button>
              </p>
            </div>
          </div>
        )
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1>AI Chat Interface</h1>
            <button className="btn btn-danger" onClick={logout}>
              Logout
            </button>
          </div>

          {errorMessage && (
            <div className="alert alert-danger mt-3">{errorMessage}</div>
          )}

          <button
            className="btn btn-secondary mb-3"
            onClick={toggleConversationPane}
          >
            {isConversationPaneOpen ? 'Hide Conversations' : 'Show Conversations'}
          </button>

          <div className="row">
            {isConversationPaneOpen && (
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h2>Conversations</h2>
                    <button className="btn btn-primary" onClick={openModal}>
                      New
                    </button>
                  </div>
                  <ul className="list-group list-group-flush">
                    {conversations.map((conv) => (
                      <li
                        key={conv._id}
                        className={`list-group-item ${
                          selectedConversation &&
                          selectedConversation._id === conv._id
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => selectConversation(conv._id)}
                      >
                        {conv.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div
              className={isConversationPaneOpen ? 'col-md-8' : 'col-md-12'}
            >
              {selectedConversation ? (
                <>
                  <div className="card">
                    <div className="card-header">
                      <h2>{selectedConversation.name}</h2>
                    </div>
                    <div
                      className="card-body"
                      style={{ maxHeight: '400px', overflowY: 'auto' }}
                    >
                      {selectedConversation.messages.map((msg, idx) => (
                        <div key={idx} className="mb-3">
                          <strong className="d-block">
                            {msg.role === 'user'
                              ? 'You'
                              : msg.role === 'system'
                              ? 'System'
                              : 'Bot'}
                            :
                          </strong>
                          {/* Render Text */}
                          {msg.content.text && (
                            <ReactMarkdown>{msg.content.text}</ReactMarkdown>
                          )}
                          {/* Render Image */}
                          {msg.content.image_url && (
                            <div>
                              <img
                                src={msg.content.image_url}
                                alt="Uploaded content"
                                className="img-fluid mt-2"
                                style={{ maxWidth: '300px' }}
                              />
                              <br />
                              <a
                                href={msg.content.image_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                              >
                                [Image Link]
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* User Input Form */}
                  <form onSubmit={handleSendMessage} className="mt-4">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-label">
                            Text Message (optional):
                          </label>
                          <textarea
                            rows={3}
                            className="form-control"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Type a text message..."
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Image URL (optional):
                          </label>
                          <input
                            type="url"
                            className="form-control"
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}
                            placeholder="Paste an image URL..."
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">
                            Upload an Image (optional):
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              handleImageUpload(file);
                            }}
                            accept="image/*"
                          />
                        </div>

                        {/* Image Preview */}
                        {imagePreviewUrl && (
                          <div className="mb-3">
                            <label className="form-label">Image Preview:</label><br />
                            <img
                              src={imagePreviewUrl}
                              alt="Uploaded content"
                              className="img-fluid"
                              style={{ maxWidth: '300px' }}
                            />
                          </div>
                        )}

                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={
                            isLoading ||
                            (!userInput && !imageUrl && !blobName)
                          }
                        >
                          {isLoading ? 'Sending...' : 'Send'}
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <p>Select a conversation to start chatting.</p>
              )}
            </div>
          </div>

          {/* Modal for New Conversation */}
          {isModalOpen && (
            <>
              {/* Modal Backdrop */}
              <div className="modal-backdrop fade show"></div>

              {/* Modal Dialog */}
              <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">New Conversation</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label className="form-label">Conversation Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter conversation name"
                          value={newConversationName}
                          onChange={(e) => setNewConversationName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Select Model</label>
                        <select className="form-select" onChange={(e) => {
                           setNewModel(e.target.value);
                           setNewProvider(e.target.selectedOptions[0].getAttribute('data-provider'));
                        }}>
                          {renderModelOptions()}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          System Prompt (optional)
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter a system prompt..."
                          rows={3}
                          value={newSystemPrompt}
                          onChange={(e) => setNewSystemPrompt(e.target.value)}
                        ></textarea>
                      </div>

                      {errorMessage && (
                        <div className="alert alert-danger mt-3">{errorMessage}</div>
                      )}
                      
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCreateConversation}
                      >
                        Create
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default App;